.p {
  width: 30%;
  height: 40vh;
  margin: 20px 10px;
  border: 2px solid rgb(218 227 229);
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
}

.p-browser {
  height: 20px;
  background-color: #dae3e5;
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 2;
}

.p-circle-i {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 3px;
  background-color: #ff5a52;
}
.p-circle-ii {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 3px;
  background-color: #e6c029;
}
.p-circle-iii {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 3px;
  background-color: #52c22b;
}

.p-img {
  width: 100%;
  transition: all 10s ease;
}

.p:hover .p-img {
  transform: translateY(-100%);
}
