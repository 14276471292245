.pl {
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.pl-texts {
  width: 65%;
}

.pl-title {
  font-size: 50px;
  font-weight: 600;
}

.pl-desc {
  margin: 20px 0px;
}

.pl-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
