.i {
  display: flex;
  height: 100vh;
  background-color: #dae3e5;
}

.i-left {
 flex: 1;
 display: flex;
 align-items: center;
 justify-content: center;
}

.i-right {
  flex: 1;
  position: relative;
}

.i-left-wrapper {
  padding: 50px;
  height: 50%;
}

.i-intro {
  font-size: 30px;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.i-name {
  font-size: 60px;
}

.i-title {
  height: 50px;
  overflow: hidden;
}

.i-title-wrapper {
  height: 100%;
  animation: move 10s ease-in-out infinite alternate;
}

@keyframes move {
  25%{
    transform: translateY(-50px)
  }
  50%{
    transform: translateY(-100px)
  }
  75%{
    transform: translateY(-150px)
  }
  100%{
    transform: translateY(-200px)
  }
}

.i-title-item {
  height: 50px;
  font-size: 30px;
  font-weight: bold;
  color: #507dbc;
  display: flex;
  align-items: center;
}

.i-scroll {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 20px;
}

.i-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.i-bg {
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
  background-color: #507dbc;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
